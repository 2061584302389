module services {
    export module payment {
        export class paymentService implements interfaces.payment.IPaymentService {
            static $inject = ["$resource", "ENV","$http","$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,private $http: ng.IHttpService,private $timeout: ng.ITimeoutService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getPayment(): ng.resource.IResourceClass<interfaces.payment.IPayment> {

                return this.$resource<interfaces.payment.IPayment>(this.ENV.DSP_URL + "Payment/GetPayment", {
                    Id: '@Id'
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                       
                    });
            }

            CreateNewVersion(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/CreateNewVersion", {
                    id: '@id',
                    functionClassificationValueId: '@functionClassificationValueId',
                    reason: '@reason'
                });
            }


            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/Save");
            }

            refreshBankingDetails(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/RefreshBankingDetails", {
                    paymentId : '@paymentId'
                    });
            }

             //Change Status
             changeStatus(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/ChangeStatus", {
                }, {
                        save: {
                            method: 'POST'
                        }
                    });
            }

            createPaymentFromInvoiceLines(ownerEntityId: number, supplierId: number, customerId: number, currencyId: number, isInBound:boolean, paymentMethodId: number, isAdvancePayment:boolean, AdvancePaymentAmount:number, paymentCounterId: number, customPaymentNumber:string): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/CreatePaymentFromInvoiceLines", {
                    ownerEntityId: ownerEntityId,
                    supplierId: supplierId,
                    customerId: customerId,
                    currencyId: currencyId,
                    isInBound: isInBound,
                    paymentMethodId: paymentMethodId,
                    isAdvancePayment : isAdvancePayment,
                    AdvancePaymentAmount : AdvancePaymentAmount,
                    paymentCounterId : paymentCounterId,
                    customPaymentNumber : customPaymentNumber

                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            updatePaymentFromInvoiceLines(paymentId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {

                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/UpdatePaymentFromInvoiceLines", {
                    paymentId: paymentId
                }, {
                        query: {
                            method: 'POST',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }

                    });
            }

            getCurrencyDropdownForSupplier(supplierId:number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Payment/GetCurrencyDropdownForSupplier", {
                    supplierId: supplierId
                });
            }

            getBOPCategoriesForPayment(ownerEntityId: number, isInbound: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Payment/GetBOPCategoriesForPayment", {
                    ownerEntityId: ownerEntityId,
                    isInbound: isInbound
                });
            }


            GetCreatePaymentDefaults(ownerEntityId: number,IsInbound:boolean,supplierId:number,customerId:number): ng.resource.IResourceClass<interfaces.payment.IPaymentCreateDefaults>
            {
                return this.$resource<interfaces.payment.IPaymentCreateDefaults>(this.ENV.DSP_URL + "Payment/GetCreatePaymentDefaults",
                    {
                        ownerEntityId: ownerEntityId,
                        IsInbound: IsInbound,
                        supplierId : supplierId,
                        customerId : customerId
                    }, {
                        get: {
                            method: 'GET',
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                        }
                    }
                );

            }

            //pass in either customerId or supplierId, setting the other to 0
            getInvoicesForPayment(ownerEntityId: number,paymentId: number, supplierId: number, customerId : number, currencyId: number, isInBound:boolean): ng.resource.IResourceClass<interfaces.payment.IPaymentInvoiceLinesDisplay> {
                return this.$resource<interfaces.payment.IPaymentInvoiceLinesDisplay>(this.ENV.DSP_URL + "Payment/GetInvoicesForPayment", {
                    ownerEntityId: ownerEntityId,
                    supplierId: supplierId,
                    paymentId : paymentId,
                    customerId: customerId,
                    currencyId: currencyId,
                    isInBound: isInBound
                });
            }

            getFECsForPayment(ownerEntityId: number, paymentId: number): ng.resource.IResourceClass<interfaces.payment.IFECAllocationDisplay> {
                return this.$resource<interfaces.payment.IFECAllocationDisplay>(this.ENV.DSP_URL + "Payment/GetFECsForPayment", {
                    ownerEntityId: ownerEntityId,
                    paymentId: paymentId
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }

            getList(): ng.resource.IResourceClass<interfaces.payment.IPaymentDisplay> {
                return this.$resource<interfaces.payment.IPaymentDisplay>(this.ENV.DSP_URL + "Payment/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                    IsInbound : '@IsInbound'
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }

             calculatePaymentDueDate(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/CalculatePaymentDueDate", {
                        IsInbound : '@IsInbound'
                    });
            }

            calculateEarlySettlementDiscount(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Payment/CalculateEarlySettlementDiscount", {
                        paymentId : '@paymentId'
                    });
            }            

            getPaymentsExcel(params: any, IsInbound:boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Payment/GetPaymentsExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                if (params.items)
                {
                    _.forEach(params.items, (item: string) => {
                        url += "&items=" + item;
                    });
                }
                    

                if (params.entityCode)
                    url += "&entityCode=" + params.entityCode;

                    url += "&IsInbound=" + IsInbound;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'Payments.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

           
        }
    }
    angular.module("app").service("paymentService", services.payment.paymentService);
}